import React, { useEffect } from 'react'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { Layout as ArcoLayout, ConfigProvider } from '@nbit/arco'
import zhCN from '@nbit/arco/lib/locale/zh-CN'
import zhHK from '@nbit/arco/lib/locale/zh-HK'
import enUS from '@nbit/arco/lib/locale/en-US'
import viVN from '@nbit/arco/lib/locale/vi-VN'
import thTH from '@nbit/arco/lib/locale/th-TH'
import jaJP from '@nbit/arco/lib/locale/ja-JP'
import koKR from '@nbit/arco/lib/locale/ko-KR'
import idID from '@nbit/arco/lib/locale/id-ID'
import msMY from '@nbit/arco/lib/locale/ms-MY'
import { I18nsEnum } from '@/constants/i18n'
import { ThemeEnum } from '@nbit/chart-utils'
import { useLayoutStore } from '@/store/layout'
import RecreationHeadeOk from '@/features/recreation/theme/ok/layout/recreation-header'
import HeaderOk from '@/features/recreation/theme/ok/layout/header'
import { PageContextProvider } from '@/hooks/use-page-context'
import ErrorBoundary from '@/components/error-boundary'
import Footer from '@/features/recreation/theme/components/footer'
import type { ComponentConfig } from '@nbit/arco/es/ConfigProvider/interface'
import LoadingElement from '@/components/loading-element'
import ListEmpty from '@/components/list-empty'
import ContentOk from '@/features/recreation/theme/ok/layout/content'
import { useCommonStore } from '@/store/common'
import { getMergeModeStatus } from '@/features/user/utils/common'
import WelcomeHomeLayout from '@/components/welcome'
import WelcomeRecreationLayout from '@/features/recreation/component/welcome'
import { getFooterShowCache, getHeaderShowCache, setFooterShowCache, setHeaderShowCache } from '@/helper/cache'
import { configResponsive } from 'ahooks'

const { Header: ArcoLayoutHeader, Footer: ArcoLayoutFooter, Content: ArcoLayoutContent } = ArcoLayout

const arcoComponentConfig: ComponentConfig = {
  Spin: {
    element: <LoadingElement />,
  },
  Table: {
    noDataElement: <ListEmpty />,
  },
}

function getHeaderShow(pageContext) {
  const urlShow = pageContext.urlParsed.search?.headerShow || getHeaderShowCache()

  if (urlShow) {
    pageContext.urlParsed.search?.headerShow && setHeaderShowCache(pageContext.urlParsed.search?.headerShow)
    return urlShow === 'true' && pageContext.layoutParams?.headerShow
  }

  return pageContext.layoutParams?.headerShow
}

function getFooterShow(pageContext) {
  const urlShow = pageContext.urlParsed.search?.footerShow || getFooterShowCache()

  if (urlShow) {
    pageContext.urlParsed.search?.footerShow && setFooterShowCache(pageContext.urlParsed.search?.footerShow)
    return urlShow === 'true' && pageContext.layoutParams?.footerShow
  }

  return pageContext.layoutParams?.footerShow
}
export default function LayoutOk({ pageContext, children }: { pageContext: PageContext; children: React.ReactNode }) {
  const isFullScreen = pageContext.layoutParams?.fullScreen
  const isRecreation = pageContext.layoutParams?.isRecreation
  const headerShow = getHeaderShow(pageContext)
  const footerShow = getFooterShow(pageContext)
  const { theme, isWelcomeHomeShow, isWelcomeRereationShow } = useCommonStore()

  const locale = pageContext.locale

  const isMergeMode = getMergeModeStatus()
  configResponsive({
    lg: 1200,
    xl: 1440,
    xxl: 1850,
    xxxl: 2560,
  })

  function getLocale(localeVal?: string) {
    switch (localeVal) {
      case I18nsEnum['zh-CN']:
        return zhCN
      case I18nsEnum['en-US']:
        return enUS
      case I18nsEnum['zh-HK']:
        return zhHK
      case I18nsEnum['vi-VN']:
        return viVN
      case I18nsEnum['th-TH']:
        return thTH
      case I18nsEnum['ja-JP']:
        return jaJP
      case I18nsEnum['ko-KR']:
        return koKR
      case I18nsEnum['id-ID']:
        return idID
      case I18nsEnum['ms-MY']:
        return msMY
      default:
        return enUS
    }
  }

  useEffect(() => {
    // 检测用户的操作系统
    const userAgent = window.navigator.userAgent
    const isWindows = userAgent.includes('Windows')

    // 设置 HTML 标签的字体大小为 13px
    if (isWindows && locale === I18nsEnum['ta-IN']) {
      document.documentElement.style.fontSize = '13px'
    }
  }, [locale])
  return (
    <PageContextProvider pageContext={pageContext}>
      <I18nProvider i18n={i18n}>
        <ConfigProvider componentConfig={arcoComponentConfig} locale={getLocale(locale) as any}>
          <div
            id="layout"
            className={`${
              isRecreation ? 'recreation-layout-wrap' : isFullScreen ? 'layout-fullscreen-wrap' : 'layout-wrap'
            } ${theme === ThemeEnum.dark ? 'dark-spin' : 'light-spin'}`}
          >
            <ErrorBoundary>
              {headerShow ? (
                <ArcoLayoutHeader className="sticky top-0 left-0 z-10 shadow-sm">
                  {isRecreation ? <RecreationHeadeOk /> : <HeaderOk />}
                </ArcoLayoutHeader>
              ) : null}
              <ArcoLayoutContent>
                {isRecreation ? <ContentOk pageContext={pageContext}>{children}</ContentOk> : children}
              </ArcoLayoutContent>
              {isMergeMode || isRecreation ? null : footerShow ? (
                <ArcoLayoutFooter>
                  <Footer />
                </ArcoLayoutFooter>
              ) : null}
            </ErrorBoundary>
            {isWelcomeHomeShow && <WelcomeHomeLayout />}
            {isWelcomeRereationShow && <WelcomeRecreationLayout />}
          </div>
        </ConfigProvider>
      </I18nProvider>
    </PageContextProvider>
  )
}
